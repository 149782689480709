import React from 'react';

import gal7 from './gal7.jpeg';
import gal9 from './gal9.jpg';
import gal10 from './gal10.jpg';
import gal11 from './gal11.jpg';
import gal12 from './gal12.jpg';
import gal13 from './gal13.jpg';
import gal14 from './gal14.jpg';
import gal15 from './gal15.jpg';
import gal16 from './gal16.jpg';
import gal17 from './gal17.jpg';
import gal18 from './gal18.jpg';
import gal19 from './gal19.jpg';
export default function Gallaryblk() {
    return (
        <div className='container'>
        <div className="gallary-block">
        <div >
                
            <div className="row text-center">
                <div className="col"> 
                    <div id="card-gal" className="card" style={{ Width: "400px=", height: "217px" }}>
                        <img src={gal7} className="card-img-top" alt="..." />
                    </div>
                </div>

                <div className="col"> 
                    <div id="card-gal" className="card" style={{ width: "400px=", height: "217px" }}>
                        <img src={gal9} className="card-img-top" alt="..." />
                    </div>
                </div>

                <div className="col"> 
                    <div id="card-gal" className="card" style={{ width: "400px=", height: "217px" }}>
                        <img src={gal10} className="card-img-top" alt="..." />
                    </div>
                </div>
            </div>


            <div  className="row text-center">
                <div className="col"> 
                    <div id="card-gal" className="card" style={{ width: "400px=", height: "217px" }}>
                        <img src={gal11} className="card-img-top" alt="..." />
                    </div>
                </div>

                <div  className="col"> 
                    <div id="card-gal" className="card" style={{ width: "400px=", height: "217px" }}>
                        <img src={gal12} className="card-img-top" alt="..." />
                    </div>
                </div>

                <div  className="col"> 
                    <div id="card-gal" className="card" style={{ width: "400px=", height: "217px" }}>
                        <img src={gal13} className="card-img-top" alt="..." />
                    </div>
                </div>
            </div>
        </div>
        </div>



        <div className="gallary-block">
        <div >
                
            <div className="row text-center">
                <div  className="col"> 
                    <div id="card-gal" className="card" style={{ width: "400px=", height: "217px"  }}>
                        <img src={gal14} className="card-img-top" alt="..."/>
                    </div>
                </div>

                <div  className="col"> 
                    <div id="card-gal" className="card" style={{ width: "400px=", height: "217px" }}>
                        <img src={gal15} className="card-img-top" alt="..." />
                    </div>
                </div>

                <div  className="col"> 
                    <div id="card-gal" className="card" style={{ width: "400px=", height: "217px" }}>
                        <img src={gal16} className="card-img-top" alt="..." />
                    </div>
                </div>
            </div>


            <div className="row text-center">
                <div  className="col"> 
                    <div id="card-gal" className="card" style={{ width: "400px=", height: "217px" }}>
                        <img src={gal17} className="card-img-top" alt="..." />
                    </div>
                </div>

                <div className="col"> 
                    <div id="card-gal" className="card" style={{ width: "400px=", height: "217px" }}>
                        <img src={gal18} className="card-img-top" alt="..." />
                    </div>
                </div>

                <div className="col"> 
                    <div id="card-gal" className="card" style={{ width: "400px=", height: "217px" }}>
                        <img src={gal19} className="card-img-top" alt="..." />
                    </div>
                </div>
            </div>
        </div>
            
      <footer className="py-3 my-4">
      <h6 className='' style={{textAlign:'center'}}>Gurukul Public School</h6>
      <hr/>
      <p className="text-center text-body-secondary"> © 2024 Gurukul Public School. All rights reserved</p>
      <p className="text-center text-body-secondary"> Designed By : Web Spirits , IT Department of SCOE</p>
    </footer>
        </div>
        </div>
    
    );
}
